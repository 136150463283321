import React from 'react';

export default function ModalActionPDV({ t, setshow, action1, action2 }) {
  return (
    <div
      className="modal fade show"
      id="desactiver"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="false"
      style={{ display: 'block' }} // Affiche le modal
    >
      <div className="modal-dialog modal-danger" role="document">
        <div className="modal-content border-0">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setshow(false)} // Ferme le modal
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h1 className="modal-title" id="modal">
             {t('Quelle fonctionnement  souhaitez-vous effectuer')}
              </h1>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={action1}>
             Page des détails
            </button>
            <button
              type="button"
              className="btn btn-secondary text-white ml-auto"
              onClick={()=>{action2();setshow(false)}} // Ferme également
            >
             voir les emplacements sur la carte
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
