/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import SqaureLoader from '../../components/Loader/SqaureLoader'
import PaginationTable from '../../components/Pagination/PaginationTable'
import { getAllListeMagasins, getListeMagasins, getListeMagasinsGrouped } from '../../services/Magasins/PdvApi'
import { SearchItems } from '../../utils/SearchUtils'
import ListePdvLocalisation from './ListePdvLocalisation'
import ModalAddFilePdv from './ModalAddFilePdv'
import ModalListeMagasins from './ModalListeMagasins'
import ModalUpdateEtatPdvs from './ModalUpdateEtatPdvs'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import ModalActionPDV from './ModalActionPDV'


export default function ListeMagasins() {

    const [CodePDV, setCodePDV] = useState(3)
    //------------------ LISTE MAGASINS --------------
    const [listeMagasins, setlisteMagasins] = useState([])
    const [saveList, setSaveList] = useState([])
    const [searchList, setSearchlist] = useState([])
    const [pdv, setpdv] = useState([])
    const inputEl = useRef("")
    const [total, settotal] = useState("")
    const [listeEnseinges, setlisteEnseinges] = useState([])
    const [listeEnseingeSelected, setlisteEnseingesSelected] = useState([])
    // Formulaire 
    const [search, setSearch] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("-1")
    // Loading 
    //PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [stocksPerPage, setStockPerPage] = useState(50);
    //Get current stock
    const indexOfLastPost = currentPage * stocksPerPage;
    const indexOfFirstPost = indexOfLastPost - stocksPerPage;
    //Current Liste 
    const currentListe = search.length < 1 ? listeMagasins.slice(indexOfFirstPost, indexOfLastPost) : searchList.slice(indexOfFirstPost, indexOfLastPost)
    const [loading, setloading] = useState(true)
    //------------- Traduction ---------------
    const [t, i18n] = useTranslation("global");
    const [ok, setOk] = useState(false) // show modal and edit on same time
    const [code, setCode] = useState('') // show modal and edit on same time
    const [showPDV, setShowPDV] = useState(false); // Initialisation avec false

    const history = useHistory()

    //Modal items
    const [show, setShow] = useState(true)
    //SHOW DATA ( actif / inactif ,....)
    const [data, setData] = useState({
        actif: 1,
    })
    //SEARCH ITEMS
    const getSearchTerm = () => {
        setSearch(inputEl.current.value)
        if (inputEl.current.value !== "") {
            const newList = SearchItems(listeMagasins, inputEl.current.value)
            setSearchlist(newList)
        } else {
            setlisteMagasins(saveList)
        }
    }
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.checked });
        console.log("checked" + e.target.checked);

        if (e.target.name === "actif") {
            if (e.target.checked === true) {
                getAllListeMagasins()
                    .then((result) => {
                        if (result?.data?.success === 1) {
                            setlisteMagasins(result?.data?.liste)
                            settotal(result?.data?.total.total)
                            setlisteEnseinges(result?.data?.localisation)

                            setSaveList(result?.data?.liste)
                        }
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        setloading(false)
                    })
            } else {
                getListeMagasins()
                    .then((result) => {
                        if (result?.data?.success === 1) {
                            setlisteMagasins(result?.data?.liste)
                            settotal(result?.data?.total.total)
                            setlisteEnseinges(result?.data?.localisation)
                            setSaveList(result?.data?.liste)
                        }
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        setloading(false)
                    })
            }
        }
    }
    //------ Filtre Enseignes 
    const onSelectEnseignes = (e) => {

        if (e.target.value !== "-1") {
            setcodeEnseigne(e.target.value)
            const liste = saveList?.filter((element) => element.nomenseigne === e.target.value)
            setlisteMagasins(liste)
        } else if (e.target.value === "-1") {
            setlisteMagasins(saveList)
        }

    }
   

    useEffect(() => {
        getListeMagasinsGrouped()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setpdv(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        if (data?.actif === 1 || data?.actif === true) {
            getAllListeMagasins()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteMagasins(result?.data?.liste)
                        settotal(result?.data?.total.total)
                        setlisteEnseinges(result?.data?.localisation)
                        setSaveList(result?.data?.liste)
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })
        } else {
            getListeMagasins()
                .then((result) => {
                    if (result?.data?.success === 1) {
                        setlisteMagasins(result?.data?.liste)
                        settotal(result?.data?.total.total)
                        setlisteEnseinges(result?.data?.localisation)
                        setSaveList(result?.data?.liste)
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                })
        }

    }, [ok])

    useEffect(()=>{
        if(listeEnseinges){
            setlisteEnseingesSelected(listeEnseinges)
        }
    },[listeEnseinges])
    const action1 = () => {
        history.push("/pdv/detailsPdv&code=" + code.code);
    }
    const action2 = () => {
        
        setlisteEnseingesSelected(listeEnseinges.filter(item => item.nomenseigne === code.code || item.nompdv === code.code))
    }
    return (
        <div>
            {/** --------------- HEADER ----------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#"><i className="fe fe-life-buoy mr-1" /> {t('dashboard.Magasin')}  </a></li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('sidebar.listeMagasins')}  </li>
                </ol>
                <div className="mt-3 mt-lg-0">
                    <div className="d-flex align-items-center flex-wrap text-nowrap">
                        <a href="/pdv/configuration" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-settings mr-1" />
                            {t('Magasins.Parametragemagasins')}
                        </a>
                        <a href="/pdv/ajouterPDV" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-plus mr-1" />
                            {t('Magasins.AjouterMagasin')}
                        </a>
                        <a href="/pdv/convention" className="btn btn-azure btn-icon-text mr-2 d-none d-md-block fs-10">
                            <i className="icon icon-docs mr-1" />
                            {t('Magasins.Convention')}
                        </a>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="row">
                {/** --------------- Tableau Magasins ----------------------- */}
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'>
                    <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
                        <div className="panel panel-default active">
                            <div className="panel-heading " role="tab" id="headingOne">
                                <h4 className="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className='fs-12'>
                                        <i className="icon icon-grid text-primary mr-2" />  {t('sidebar.listeMagasins')}
                                        <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                                    </a>

                                </h4>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div className="panel-body">
                                    <div className="search1 search-elements-sm mb-4 ">
                                        <div className="form row no-gutters">
                                            <div className="form-group  col-xl-1 col-lg-1 col-md-1 mb-0" />
                                            <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                                                <input type="text" ref={inputEl} value={search}
                                                    onChange={() => getSearchTerm()} className="form-control br-tr-md-0 br-br-md-0 text-primary fs-10" id="text1" placeholder={t('Magasins.chercherunmagasins')} />
                                            </div>
                                            <div className="form-group  col-xl-5 col-lg-5 col-md-5 mb-0">
                                                <select className="form-control br-md-0 fs-10" value={codeEnseigne} onChange={(e) => onSelectEnseignes(e)}>
                                                    <option value="-1"> {t('Magasins.Choisirenseigne')}  </option>
                                                    {pdv?.map((items) => (
                                                        <option value={items.nomenseigne} className="fs-10"> {items.nomenseigne} </option>
                                                    ))}

                                                </select>
                                            </div>
                                            <span className="input-group-append">
                                                <a href="#" className="btn btn-primary"><i className="icon icon-magnifier" /></a>
                                            </span>
                                            <div className="form-group  col-xl-1 col-lg-1 col-md-1 mb-0" />
                                        </div>
                                    </div>

                                    <div className="tags mb-2 ">
                                        {pdv?.map((items, keys) => (
                                            <span className="tag  fs-10" key={keys} style={{ background: items.color !== null ? items.color : "#ff6600" }}> <span onClick={() => {
                                                setShowPDV(true);
                                                setCode(items)
                                            }} style={{ color: "#fff" }}> {items.nomenseigne} : ({items.total}) </span></span>
                                        ))}
                                    </div>
                                    <p className=' fs-12'>(*) Voulez vous afficher les magasins inactif ?    <input
                                        type="checkbox"
                                        name="actif"
                                        defaultChecked={data.actif}
                                        onChange={(e) => handleChange(e)}
                                    /><span className="fs-12">  Oui/ Non</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">


                        <div className="card-body">


                            <div className="row">
                                <div className='col-12 mb-2'>
                                    <button className='btn btn-secondary float-right mr-1 fs-12' data-toggle="modal" data-target="#exportMagasins" ><i className="icon icon-share-alt mr-1" /> {t('rapportrupture.exporter')} </button>
                                    <button className='btn btn-success float-right mr-1 fs-12' data-toggle="modal" data-target="#exportProduits" ><i className="icon icon-share-alt mr-1" /> {t('Produits.Importer')} </button>
                                </div>

                                <div className="table-responsive  product-datatable" style={{ overflow: 'auto', height: '600px' }}>
                                    <table className="table  table-bordered ">
                                        <thead className="text-center" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >
                                            <tr>
                                                <td colSpan="8" className="text-center bg-purple text-white fs-10" >{t('dashboard.totPdvs')} : {total}  </td>
                                            </tr>
                                            <tr>
                                                <th className="w-15p fs-10" >{t('dashboard.Enseigne')}</th>
                                                <th className="wd-15p fs-10" >{t('rapports.code')}</th>
                                                <th className="wd-15p fs-10" >{t('dashboard.Magasin')}</th>
                                                <th className="wd-15p fs-10" >{t('Magasins.Assortiments')}</th>
                                                <th className="wd-15p fs-10" >{t('dashboard.region')}</th>
                                                <th className="wd-15p fs-10" >{t('rapportrupture.merch')}</th>
                                                <th className="wd-15p fs-10" >{t('rapportrupture.categorie')}</th>
                                                <th className="wd-15p fs-10" >{t('rapports.ACTION')}</th>
                                            </tr>
                                        </thead>
                                        {loading ? <SqaureLoader /> : (
                                            <tbody>
                                                {currentListe?.map((items, keys) => (
                                                    <tr key={keys} className={items.isactif !== "1" ? 'bg-red-lightest  text-center' : " text-center"}>
                                                        <td className='fs-10 text-left'>
                                                            <i className='fa fa-stop mr-2' style={{ color: items.color !== null ? items.color : "#ff6600" }} />
                                                            <b>{items.nomenseigne}</b> </td>
                                                        <td className='fs-10'>
                                                            <a href={"/pdv/historique&code=" + items.code}><u className="text-primary">{items.code} </u></a>
                                                        </td>
                                                        <td className='fs-10'><a href={"/pdv/modifierPDV&code=" + items.code}> <u className="text-success"> {items.nompdv} </u> </a></td>
                                                        <td className='fs-10'> {items.sum_ass} </td>
                                                        <td className='fs-10'> <a className='text-primary' href={"/planningPdv/pdv_code=" + items.code + "&categorie_code=" + items.categoriepdv_code}> <u>{items.regions !== null ? items.regions : "Aucune"} </u></a></td>
                                                        <td className='fs-10'>{items.nom !== null ? items.nom : <h6 className="text-danger"> - </h6>}</td>
                                                        <td className='fs-10'> <a className='text-primary' href={"/planningPdv/pdv_code=" + items.code + "&categorie_code=" + items.categoriepdv_code}> <u>{items.categoriepdv} </u></a></td>
                                                        <td className='fs-10'> <button className="btn btn-danger btn-sm btn-square text-center" data-toggle="modal" data-target="#desactiver" onClick={() => { setCodePDV(items.code) }} > <i className='ti-na' /> </button></td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}

                                    </table>

                                </div>
                                <div className='row'>
                                    <div className='col-4' />
                                    <div className='col-4'>
                                        <div className=" text-center mt-3">
                                            <PaginationTable
                                                postsPerPage={stocksPerPage}
                                                totalPosts={search.length < 1 ? listeMagasins.length : searchList.length}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4' />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/**----------------------- Localisation -------------------- */}
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <ListePdvLocalisation listeEnseinges={listeEnseingeSelected} loading={loading} t={t} />
                    </div>
                </div>
            </div>
            <ModalListeMagasins listeMagasins={listeMagasins} t={t} loading={loading} />
            <ModalAddFilePdv setShow={setShow} t={t} />
            {CodePDV !== "" && <ModalUpdateEtatPdvs setOk={setOk} t={t} code={CodePDV} />}
            {showPDV && (
        <ModalActionPDV
          t={t}
          setshow={setShowPDV}
          action1={action1}
          action2={action2}
        />
      )}
        </div>
    )
}
